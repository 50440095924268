import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageridesService, RideMaster, CoreEvalutionRider } from '../managerides.service';
import { ToastrService } from 'ngx-toastr';



import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';

import { debug } from 'util';
import { UtcToLocal } from '../../../pipes/utctolocal.pipe';


@Component({
  selector: 'app-rideevalution',
  templateUrl: './rideevalution.component.html',
  styleUrls: ['./rideevalution.component.scss']
})
export class RideevalutionComponent implements OnInit {

  RiderId: any;
  loading = false;
  RiderMasterId: any;
  submitted = false;
  PreRideSBP: any;
  PreRideDBP: any;
  RiderRecipeDetailModel: [];
  isPreRideSBP = false;
  isPreRideDBP = false;
  runridebtn: boolean = false;
  isHide = true;
  totalRows: any;
  Spin = false;
  Angle = false;
  loadSave = false;
  Rotation = false;
  RideData: RideMaster;
  CurrentRideUserDetail: any;
  name: any;
  FirstName: string;
  email: any;
  LastName: string;
  isRemove: boolean = false;
  hiddenddbtn: any;
  CurrentRiderId: any;
  isRedirectTosession = false;
  Dob: any;
  Gender: any;
  RideStartTime: any;
  RiderAdminId: any;
  RotIdDDL: any;
  rotationList: any;
  ridemasterId: any;
  ipAddress: any;
  isLast: boolean = false;
  deletebutton: 0;
  IEbrowser: boolean = false;
  MachineAssignedName: any;
  FromWeb: boolean = true;
  LoginId: any;
  AssignedTo: any;
  spinMessage: string = "Spin is required";
  isFlag: boolean = false;
  isWaiverCollected: boolean = false;
  actualRiderId: any = null;
  //FirstName: string;
  //LastName: string;
  //CurrentRiderId: any;
  //Dob: any;
  //RiderAdminId: any;
  //name: any;
  //Gender: any;
  //LoginId: any;
  //loading = false;
  //MachineAssignedName: any;
  //AssignedTo: any;
  SpinList: any = ['0', '20', '40', '60']
  //RiderRecipeDetailModel: [];
  //FromWeb: boolean = true;
  //RideData: RideMaster;
  evalutionRide: FormGroup;

  EvalutionRideData: CoreEvalutionRider;
  selectedEvaluationAnge:any=null;
  EvaluationCoreScrore: any;
  TotalTime: any;
  LeftTime: any;
  RightTime: any;
  AnteriorTime: any;
  PosteriorTime: any;
  MinTime: number;
  MaxTime: number;
  riderDate: string;
  mutipler: number;
  angle: any;
  personalBest: any;
  rideType: number = 0;
  coreScore: any;
  constructor(private fb: FormBuilder,
    private manageridesService: ManageridesService,
    private commonService: CommonService,
    private router: Router, private activeRoute: ActivatedRoute,
    private toaster: ToastrService, private utcToLocal: UtcToLocal,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('id');
        this.actualRiderId = params.get('riderId');
      }
    );
    //this.selectedEvaluationAnge = "0";
    this.runridebtn = false;
    this.EvaluationCoreScrore = "0";
    this.TotalTime = 0;
    this.AnteriorTime = 0;
    this.PosteriorTime = 0;
    this.LeftTime = 0;
    this.RightTime = 0;
    this.MinTime = 0;
    this.MaxTime = 0;
    this.mutipler = 1;

    this.initForm();
    this.getEvalutionListDetails();
    this.getCurrentRiderRecipeDetail();
  }


  private initForm() {
    this.evalutionRide = this.fb.group({
      postRideSBP: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      postRideDBP: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      restingHeartRate: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      bMI: ['00'],
      weight: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],

      heightFeet: ['', [Validators.pattern("[0-9]+")]],
      heightInch: ['', [Validators.pattern("[0-9]+")]],
      ambientTemp: ['', [Validators.pattern("^[0-9]{0,3}.?[0-9]{0,3}$")]],
      fullSpecturum: false,
      Id: [null],
      RideEndTime: [''],
      EmailAfterRides: [''],
      angle: [''],
      rideType: [null],
      CoreScore: new FormControl(null, Validators.nullValidator),

      EvalutionRidesAngleList: this.fb.array([])
    });
  }
  calculateScoreValuesForBMI(event, section) {
    let heightIntoFit = ((parseInt(this.heightFeet.value == '' ? 0 : this.heightFeet.value) * 12) + parseInt(this.heightInch.value == '' ? 0 : this.heightInch.value));
    let total;
    if ((this.heightInch.value != "" || this.heightFeet.value != '') && this.weight.value != '') {
      total = (parseInt(this.weight.value == '' ? 0 : this.weight.value) / heightIntoFit / heightIntoFit) * 703
      if (isNaN(total)) {
        this.bMI.setValue(Math.round(0));
      }
      else if ((total === Number.POSITIVE_INFINITY) || (total === Number.NEGATIVE_INFINITY))
      {
        this.bMI.setValue(Math.round(0));
      }
      else {
        this.bMI.setValue(Math.round(total));
      }
      
    }
    else {
      this.bMI.setValue(0);
    }


  }

  //get postRideSBP() { return this.evalutionRide.get('postRideSBP'); }
  //get postRideDBP() { return this.evalutionRide.get('postRideDBP'); }
  get CoreScore() { return this.evalutionRide.get('CoreScore'); }
  get restingHeartRate() { return this.evalutionRide.get('restingHeartRate'); }
  get postRideSBP() { return this.evalutionRide.get('postRideSBP'); }
  get postRideDBP() { return this.evalutionRide.get('postRideDBP'); }
  get bMI() { return this.evalutionRide.get('bMI'); }
  get weight() { return this.evalutionRide.get('weight'); }
  get heightInch() { return this.evalutionRide.get('heightInch'); }
  get ambientTemp() { return this.evalutionRide.get('ambientTemp'); }
  get heightFeet() { return this.evalutionRide.get('heightFeet'); }
  get Id() { return this.evalutionRide.get('Id'); }
  get RideEndTime() { return this.evalutionRide.get('RideEndTime'); }
  get EmailAfterRides() { return this.evalutionRide.get('EmailAfterRides'); }

  RideMaster = {
    PreRideSBP: '',
    PreRideDBP: '',
  };
  get EvalutionRidesAngleList(): FormArray {
    return this.evalutionRide.get('EvalutionRidesAngleList') as FormArray;
  }
  getEvalutionListDetails() {
    let current = this;
    debugger
    this.EvalutionList.forEach(function (value) {

      let group = new FormGroup({
        Id: new FormControl(value.Id),
        Spin: new FormControl(null, Validators.required),
        Direction: new FormControl(value.Direction),
        Degree: new FormControl(value.Degree, Validators.required),
      });
      current.EvalutionRidesAngleList.push(group);
    });
  }

  getCurrentRiderRecipeDetail() {
    this.RiderAdminId = localStorage.getItem("RiderAdminid");
    // this.MachineAssignedName = localStorage.getItem("MachineAssignedName");
    this.manageridesService.getCurrentRiderRecipeDetail(this.RiderAdminId,'2').subscribe((data: any) => {
      data.forEach(childObj => {
        this.name = childObj.firstName + ' ' + childObj.lastName;
        this.FirstName = childObj.firstName;
        this.LastName = childObj.lastName;
        this.CurrentRiderId = childObj.riderId;
        this.Gender = childObj.gender;
        this.Dob = childObj.dob;
        this.AssignedTo = childObj.assignedTo;
        this.MachineAssignedName = childObj.machineName;
        //this.coreScore = childObj.coreScore;
        this.riderDate = childObj.riderDate;
        this.email = childObj.email;
        this.angle = childObj.angle;
        this.personalBest = childObj.personalBest;
        this.rideType = childObj.rideType
        this.isWaiverCollected = childObj.collectWaiver;
        this.heightFeet.setValue(childObj.heightFeet);
        this.heightInch.setValue(childObj.heightInch);
        
      });
      //if (this.AssignedTo != this.LoginId) {
      //  this.router.navigateByUrl('/unauthorized');
      //}
     
    })
  }
  cancelForm() {
    this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
   // this.router.navigate(['/rides/evalution-baseline', this.RiderId, this.RiderAdminId]);
  }
  GoToloadSession() {
    this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
  }
  EvalutionList = [
    { Id: 1, Spin: '', Direction: 'Anterior', Degree: '' },
    { Id: 2, Spin: '', Direction: 'Posterior', Degree: '' },
    { Id: 3, Spin: '', Direction: 'Right Lateral', Degree: '' },
    { Id: 4, Spin: '', Direction: 'Left Lateral', Degree: '' }
  ];

  transformDate(date) {
    let newDate = new Date(date);
    return this.utcToLocal.transform(date, '');
  }

  SaveRide() {
    this.loadSave = true;
    if (this.evalutionRide.valid) {
      this.EvalutionRideData = new CoreEvalutionRider();
      this.EvalutionRideData.RiderId = this.RiderId;

      this.EvalutionRideData.CoreEvalutionRiderDetail = this.evalutionRide.value.EvalutionRidesAngleList;

      this.EvalutionRideData.CoreScore = this.coreScore;
      this.EvalutionRideData.postRideSBP = this.evalutionRide.value.postRideSBP;
      this.EvalutionRideData.postRideDBP = this.evalutionRide.value.postRideDBP;

      this.EvalutionRideData.PersonalBest = this.personalBest;
      this.EvalutionRideData.newCoreScore = this.evalutionRide.value.CoreScore;
      this.EvalutionRideData.restingHeartRate = this.evalutionRide.value.restingHeartRate;
      this.EvalutionRideData.weight = this.evalutionRide.value.weight;

      this.EvalutionRideData.heightFeet = this.evalutionRide.value.heightFeet;
      this.EvalutionRideData.heightInch = this.evalutionRide.value.heightInch;
      this.EvalutionRideData.ambientTemp = this.evalutionRide.value.ambientTemp;
      this.EvalutionRideData.bMI = this.evalutionRide.value.bMI;
      this.EvalutionRideData.fullSpecturum = this.evalutionRide.value.fullSpecturum;
      
      this.EvalutionRideData.riderDate = this.transformDate(this.riderDate);
      
      this.EvalutionRideData.riderName = this.name;
      this.EvalutionRideData.email = this.email;
      this.manageridesService.SaveEvaluation(this.EvalutionRideData, this.FromWeb)
        .subscribe((result: any) => {
          if (result.statusCode === 200) {
            this.toaster.success(result.responseMessage);
           // this.router.navigate(['/rides/ride-list', this.RiderId]);
            
            setTimeout(() => {
              this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
              this.loadSave = false;
            }, 500);
            //localStorage.setItem('EvalutionCoreScore', this.EvalutionRideData.CoreScore) ride-info
          }
          else {
            this.toaster.error(result.responseMessage);
            this.loadSave = false;
          }

        });
    }
    else {
      this.commonService.validateAllFormFields(this.evalutionRide,false);

      setTimeout(() => {
        var element = document.getElementById("noscroll");
        if (element != null) {
         
          element.scrollIntoView({ behavior: 'smooth' });
          element.scrollTop;
        }
        //element.scrollIntoView({ behavior: 'smooth' });
        //element.scrollTop = element.scrollHeight;
      }, 0)
      this.loadSave = false;
    }
  }
  numberOnly(event): boolean {
    this.Angle = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onAngleChange(event): string {

    let arrayControl = this.evalutionRide.get('EvalutionRidesAngleList') as FormArray;
    let item = arrayControl;
    arrayControl.controls.forEach((t, index) => {
      debugger
      console.log("ValuesFormArray", t);
      arrayControl.at(index).patchValue
      ({
        Id: t.value.Id, Direction: t.value.Direction, Degree: t.value.Degree,
        Spin: this.selectedEvaluationAnge
      })
      
      
      
      console.log("ValuesFormArray", index);
    })

    if (this.selectedEvaluationAnge === "0")
      this.mutipler = 9;
    else if (this.selectedEvaluationAnge === "20")
      this.mutipler = 6;
    else if (this.selectedEvaluationAnge === "40")
      this.mutipler = 3;
    else if (this.selectedEvaluationAnge === "60")
      this.mutipler = 1;
    this.calculateEvalScore();
    return "";
  }
  calculateEvalScore() {
    let MaxCal = this.MaxTime;
    let PosteriorCal = this.PosteriorTime;
    this.CoreScore.setValue(0);
    this.EvaluationCoreScrore = this.mutipler * this.TotalTime * (((this.MinTime / MaxCal) + (this.AnteriorTime / PosteriorCal)) / 2)
    console.log("mutipler", this.mutipler);
    console.log("TotalTime", this.TotalTime);
    console.log("MinTime", this.MinTime);
    console.log("MaxCal", MaxCal);
    console.log("AnteriorTime", this.AnteriorTime);
    console.log("PosteriorCal", PosteriorCal);
    if (isNaN(this.EvaluationCoreScrore)) { this.EvaluationCoreScrore = "0"; }
    if ((this.EvaluationCoreScrore === Number.POSITIVE_INFINITY) || (this.EvaluationCoreScrore === Number.NEGATIVE_INFINITY)) { this.EvaluationCoreScrore = "0"; }
    this.CoreScore.setValue(this.EvaluationCoreScrore=='0'?0:this.EvaluationCoreScrore.toFixed(2));
  }
  calculateScoreValues(event, i): string {
    debugger
    let scoreTime = event.target.value;
    if (scoreTime === "") scoreTime = "0";
    //this.TotalTime += parseInt(scoreTime);


    if (this.selectedEvaluationAnge === "0")
      this.mutipler = 9;
    else if (this.selectedEvaluationAnge === "20")
      this.mutipler = 6;
    else if (this.selectedEvaluationAnge === "40")
      this.mutipler = 3;
    else if (this.selectedEvaluationAnge === "60")
      this.mutipler = 0;
    if (i === 0) {
      //Right
      this.AnteriorTime = scoreTime;
    }
    else if (i === 1) {
      //Right
      this.PosteriorTime = scoreTime;
    }
    else if (i === 2) {
      //Right
      this.RightTime = scoreTime;
    }
    else if (i === 3) {
      //Left
      this.LeftTime = scoreTime;
    }
    this.TotalTime = parseInt(this.AnteriorTime) + parseInt(this.PosteriorTime) + parseInt(this.RightTime) + parseInt(this.LeftTime);

    this.MinTime = Math.min(this.RightTime, this.LeftTime);
    this.MaxTime = Math.max(this.RightTime, this.LeftTime);



    this.calculateEvalScore();

    return "";
  }
 
  downloadWaiverDetail() {
    debugger
    console.log("downloadWaiverDetail", this.actualRiderId);
    this.manageridesService.ClickToDownload(this.actualRiderId).subscribe((result: any) => {
      console.log("result blob1", result);
      var filename = "Waiver_" + this.FirstName + '_' + this.LastName + new Date().getHours() + ".pdf";
      var file = new Blob([result], { type: result.type });
      saveAs(file, filename);
    },
      error => {
      });
  }
  Signwavebutton() {
    this.runridebtn = true;
    this.isRedirectTosession = true;
    this.router.navigate(['/sign/concent', this.actualRiderId, this.RiderId]);
    
  }
}
